<template>
  <div>
    <van-form v-if="student.id === 0" ref="form" validate-first>
      <van-field v-model="student.cnName" name="学员姓名" maxlength="6" label="学员姓名" placeholder="学员姓名" :rules="[{ required: true, message: '请填写学员姓名' }]" />
      <van-field v-model="student.mobile" type="tel" maxlength="11" name="联系方式" label="联系方式" placeholder="联系监护人方式"
        :rules="[{ required: true, message: '请填写监护人联系方式' }]" />
      <div style="margin: .5rem;">
        <van-button block type="info" native-type="button" @click="searchStudent">查询</van-button>
      </div>
      <div v-if="noResult" class="no-result">没有找到相应学员信息<br />请联系老师确认查询条件是否正确！</div>
    </van-form>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'
import eduApi from '@/api/edu'
export default {
  data() {
    return {
      msg: '',
      student: {
        id: 0,
        cnName: '',
        mobile: ''
      },
      noResult: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    searchStudent() {
      this.$refs.form
        .validate()
        .then(succ => {
          eduApi.searchStudent(this.student).then(res => {
            if (res.succ) {
              this.noResult = res.data.noResult
              if (!this.noResult) {
                this.$router.push(`/${this.appId}/edu/student/${res.data.item}/boundWxUser`)
              }
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmBound() {
      this.$dialog
        .confirm({
          title: '确认绑定',
          message: '确定绑定学员信息吗？'
        })
        .then(() => {
          eduApi.studentBoundWxUser(this.student.id).then(res => {
            if (res.succ) {
              this.$router.push(`/${this.appId}/guardian`)
            } else {
              this.step = 'error'
              this.errMsg = res.message
            }
          })
        })
      // .catch(() => {
      //   // on cancel
      // })
    }
  }
}
</script>
<style lang="less" scoped>
.no-result {
  font-size: 0.4rem;
  color: red;
  text-align: center;
}
.btn-wrapper {
  margin: 0.4rem 0;
}
</style>
